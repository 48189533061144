import * as React from "react";
import { observer } from "mobx-react";
import { withStores } from "stores";

import {
  FlightsTripType,
  FlightsSearchForm as SearchForm,
  FlightsSearchFormProps as SearchFormProps,
} from "@shared-ui/retail-search-tools-product";

import { FlightSearchFormProps } from "./typings";

import { useExperiment } from "@shared-ui/experiment-context";
import { triggerFlightsPredictiveSearchURLVariant } from "src/utils/FlightsPredictiveSearchUtils";
import { Field } from "@egds/react-core/form";
import { useSRPPrefetchAssetsExperiment } from "src/components/utility/useSRPPrefetchAssetsExperiment";
import { PrefetchProductType } from "src/components/utility/usePrefetchAssets";

/**
 * Wrapper for `@shared-ui/search-tools-product` FlightsSearchForm
 * Powered by https://flexmanager.prod.expedia.biz/app/legacy/moduleStore/show/394
 */
export const FlightSearchForm = withStores(
  "context",
  "page"
)(
  observer((props: FlightSearchFormProps) => {
    const { templateComponent, page } = props;

    /* istanbul ignore if */
    if (!templateComponent) {
      return null;
    }

    const { metadata, config } = templateComponent;
    const { id } = metadata;
    const { prefetchSRPAssets } = useSRPPrefetchAssetsExperiment(PrefetchProductType.FLIGHTS_SRP);
    const { enableFareCalendar, sticky, origin, destination, isPrefillOriginAirport } = config;

    const { exposure: preemptiveSearchExposureFSR } = useExperiment("FSR_PREEMPTIVE_SEARCH");

    const shouldPrefillCriteria = !!origin || !!destination;
    const flightsSearchCriteria = shouldPrefillCriteria
      ? {
          flightSearchCriteria: {
            primary: {
              journeyCriterias: [
                {
                  origin: origin || "",
                  destination: destination || "",
                  departureDate: {
                    day: 1,
                    month: 1,
                    year: 1970,
                  },
                },
              ],
              travelers: [],
              tripType: FlightsTripType.ROUND_TRIP,
            },
            secondary: null,
          },
        }
      : {};

    /* istanbul ignore next */
    const searchFormProps: SearchFormProps = {
      inputs: {
        includeFlightType: true,
        ...flightsSearchCriteria,
      },
      redirectToSearchResultOnSubmit: true,
      batchKey: "flights-search-form",
      onFieldChange: async (field: Field, event: React.SyntheticEvent, queryParams: string) => {
        const isFlightsPredictiveSearch = preemptiveSearchExposureFSR.bucket === 1;
        const isDateChanged =
          field?.name === "EGDSDateRangePicker" ||
          field?.name === "EGDSSingleDatePicker" ||
          field?.name === "EGDSSingleDatePicker-StartDate" ||
          field?.name === "FlightsFareCalendar-ROUND_TRIP" ||
          field?.name === "FlightsFareCalendar-ONE_WAY";
        triggerFlightsPredictiveSearchURLVariant(isFlightsPredictiveSearch, isDateChanged, queryParams);
        prefetchSRPAssets(field);
      },
      useFareCalendar: enableFareCalendar ?? false,
      features: isPrefillOriginAirport ? ["PREFILL_ORIGIN_AIRPORT"] : null,
      sticky: sticky ?? false,
      additionalAdapterFields: { xPageId: page?.pageId ?? "" },
      onOpenLocationField: undefined,
      updateLatLongOnSelection: undefined,
      onOpenDatesField: undefined,
      enableReflection: true,
    };

    return (
      <div id={id} data-testid={id}>
        <SearchForm {...searchFormProps} />
      </div>
    );
  })
);

FlightSearchForm.displayName = "FlightSearchForm";

export default FlightSearchForm;
